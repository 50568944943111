import React from 'react';

import Icon from './Icon';

export default function ReadOnlySystem({
  text = 'ReadOnlySystem',
}: { text?: string; } = {}): JSX.Element {
  return (
    <Icon text={text}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5 2C3.89543 2 3 2.89543 3 4V12C3 13.1046 3.89543 14 5 14H11C12.1046 14 13 13.1046 13 12V5.82843C13 5.29799 12.7893 4.78929 12.4142 4.41421L10.5858 2.58579C10.2107 2.21071 9.70201 2 9.17157 2H5ZM9.03033 4.97947L10.5303 6.47947C10.6022 6.55137 10.6565 6.63425 10.6931 6.72271C10.7298 6.81114 10.75 6.9081 10.75 7.0098C10.75 7.11149 10.7298 7.20845 10.6931 7.29688C10.6565 7.38534 10.6022 7.46822 10.5303 7.54013C10.4584 7.61203 10.3755 7.66629 10.2871 7.70289C10.1987 7.73956 10.1017 7.7598 10 7.7598H6C5.58579 7.7598 5.25 7.42401 5.25 7.0098C5.25 6.59558 5.58579 6.2598 6 6.2598H8.18934L7.96967 6.04013C7.67678 5.74723 7.67678 5.27236 7.96967 4.97947C8.26256 4.68657 8.73744 4.68657 9.03033 4.97947ZM6.96967 12.0179L5.46967 10.5179C5.39776 10.446 5.34351 10.3631 5.30691 10.2747C5.27024 10.1862 5.25 10.0893 5.25 9.98758C5.25 9.88589 5.27024 9.78892 5.30691 9.70049C5.34351 9.61203 5.39776 9.52916 5.46967 9.45725C5.54158 9.38534 5.62445 9.33109 5.71291 9.29449C5.80134 9.25782 5.89831 9.23758 6 9.23758L10 9.23758C10.4142 9.23758 10.75 9.57337 10.75 9.98758C10.75 10.4018 10.4142 10.7376 10 10.7376H7.81066L8.03033 10.9572C8.32322 11.2501 8.32322 11.725 8.03033 12.0179C7.73744 12.3108 7.26256 12.3108 6.96967 12.0179Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
}
