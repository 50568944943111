import React from 'react';

import styles from './CountLabel.module.css';

export const CountLabel = ({
  isActive = false,
  count,
  className = '',
}: { isActive?: boolean; count: number; className?: string | undefined; }) => {
  return (
    <span className={[styles.count, className, isActive ? styles.activeCount : ''].join(' ')}>
      {count}
    </span>
  );
};
