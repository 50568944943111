import React, { useEffect, useRef, useState } from 'react';
import isComposing from 'shared/foreground/utils/isComposing';

import fixAndTrapFocus from '../../utils/fixAndTrapFocus';
import Button from '../Button';
import PlusInCircleIcon from '../icons/PlusInCircleIcon';
import Overlay from '../Overlay';
import styles from './EditableImage.module.css';

export default function EditableImage({
  name,
  defaultValue = '',
  onChange,
}: {
  name: string;
  onChange: (newValue: string) => void;
  defaultValue?: string;
}): JSX.Element {
  const menuElement = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState(defaultValue);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen && menuElement.current) {
      fixAndTrapFocus(menuElement.current);
    }
  }, [menuElement, isMenuOpen]);

  const closeMenu = () => {
    setIsMenuOpen(false);
    document.getElementById('coverButton')?.focus();
  };

  useEffect(() => {
    setValue(value);
  }, [value]);

  const onCancel = () => {
    setValue(defaultValue);
    closeMenu();
  };

  const onSave = () => {
    onChange(value);
    closeMenu();
  };

  useEffect(() => {
    if (menuElement.current) {
      menuElement.current.scrollIntoView();
    }
  }, [menuElement]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isComposing(event)) {
      return;
    }
    if (event.key === 'Escape') {
      event.preventDefault();
      setValue(value);
      closeMenu();
      (event.target as HTMLInputElement).blur();
    } else if (event.key === 'Enter') {
      event.preventDefault();
      onSave();
    }
  };

  return (
    <div className={styles.editableImage}>
      <button id="coverButton" type="button" onClick={() => setIsMenuOpen(true)}>
        {value &&
          <div className={styles.imageWrapper}>
            <div className={styles.imageOverlay}>
              <span>Edit</span>
            </div>
            <div className={styles.image} style={{ backgroundImage: `url(${value})` }} />
          </div>
        }
        {!value &&
          <div className={styles.placeholder}>
            <PlusInCircleIcon />
            Add image
          </div>
        }
      </button>

      {isMenuOpen &&
        <>
          <Overlay onClick={closeMenu} />
          <div className={styles.menu} ref={menuElement}>
            <label htmlFor="coverUrl" className={styles.label}>
              Image URL
            </label>
            <input
              name="coverUrl"
              type="url"
              aria-label="Cover URL"
              autoComplete="off"
              placeholder="https://"
              autoFocus
              onKeyDown={onKeyDown}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                // Scroll to the start of text in input
                e.target.setSelectionRange(0, 0);
              }}
            />
            <div className={styles.buttons}>
              <Button onClick={onCancel}>Cancel</Button>
              <Button variant="default" onClick={onSave}>
                Save
              </Button>
            </div>
          </div>
        </>
      }
    </div>
  );
}
