import React from 'react';

import Icon from './Icon';

export default function CircleCancel({ className = '' }: { className?: string; }): JSX.Element {
  return (
    <Icon text="Cancel">
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM8.53033 7.46967L12 10.9393L15.4697 7.46967C15.7626 7.17678 16.2374 7.17678 16.5303 7.46967C16.8232 7.76256 16.8232 8.23744 16.5303 8.53033L13.0607 12L16.5303 15.4697C16.8232 15.7626 16.8232 16.2374 16.5303 16.5303C16.2374 16.8232 15.7626 16.8232 15.4697 16.5303L12 13.0607L8.53033 16.5303C8.23744 16.8232 7.76256 16.8232 7.46967 16.5303C7.17678 16.2374 7.17678 15.7626 7.46967 15.4697L10.9393 12L7.46967 8.53033C7.17678 8.23744 7.17678 7.76256 7.46967 7.46967C7.76256 7.17678 8.23744 7.17678 8.53033 7.46967Z" />
      </svg>
    </Icon>
  );
}
